import { default as indexA6CkBJ9gD7Meta } from "/__w/landing-page/landing-page/pages/about-us/index.vue?macro=true";
import { default as indexEM211K2zS6Meta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/booked/index.vue?macro=true";
import { default as indexSvvPPaDEsYMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/index.vue?macro=true";
import { default as room_45typesL6iEgJeU6EMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/room-types.vue?macro=true";
import { default as indexzRSeNrpqWQMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/index.vue?macro=true";
import { default as indexkBQnKs7X59Meta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/payment/index.vue?macro=true";
import { default as indexOh8KX4Gi5EMeta } from "/__w/landing-page/landing-page/pages/accommodations/[id]/success/index.vue?macro=true";
import { default as indexo8MiGLJIwpMeta } from "/__w/landing-page/landing-page/pages/accommodations/index.vue?macro=true";
import { default as indexQWTfd6C61KMeta } from "/__w/landing-page/landing-page/pages/account/index.vue?macro=true";
import { default as indexOaCdqv0UnnMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/booked/index.vue?macro=true";
import { default as indexn4yh0WphGFMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/confirm-booking/index.vue?macro=true";
import { default as indexREkxgbTVd0Meta } from "/__w/landing-page/landing-page/pages/activities/[id]/index.vue?macro=true";
import { default as indexa4TrHMsfftMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/payment/index.vue?macro=true";
import { default as indexpAvpVVXWYrMeta } from "/__w/landing-page/landing-page/pages/activities/[id]/success/index.vue?macro=true";
import { default as indexXHXZeRuJA5Meta } from "/__w/landing-page/landing-page/pages/activities/index.vue?macro=true";
import { default as forgot_45passwordjkAmvAWroOMeta } from "/__w/landing-page/landing-page/pages/auth/forgot-password.vue?macro=true";
import { default as register6FpZsYOtmdMeta } from "/__w/landing-page/landing-page/pages/auth/register.vue?macro=true";
import { default as reset_45passwordotBAwKl35NMeta } from "/__w/landing-page/landing-page/pages/auth/reset-password.vue?macro=true";
import { default as validate_45emailc3uObsadH8Meta } from "/__w/landing-page/landing-page/pages/auth/validate-email.vue?macro=true";
import { default as validate_45phone3ATy3zcvWrMeta } from "/__w/landing-page/landing-page/pages/auth/validate-phone.vue?macro=true";
import { default as indexUcDTjTUthwMeta } from "/__w/landing-page/landing-page/pages/bye/index.vue?macro=true";
import { default as indexzjPi8hX5lZMeta } from "/__w/landing-page/landing-page/pages/community/index.vue?macro=true";
import { default as indexr8bCY4aDPWMeta } from "/__w/landing-page/landing-page/pages/contact-us/index.vue?macro=true";
import { default as index4lH3SXID4XMeta } from "/__w/landing-page/landing-page/pages/cookies/index.vue?macro=true";
import { default as indexwj1zLhUy00Meta } from "/__w/landing-page/landing-page/pages/destinations/[id]/index.vue?macro=true";
import { default as indexTw4dUykH0bMeta } from "/__w/landing-page/landing-page/pages/destinations/index.vue?macro=true";
import { default as indexChFVAVuhdIMeta } from "/__w/landing-page/landing-page/pages/donatella/index.vue?macro=true";
import { default as indexvUGuDSmPdXMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/booked/index.vue?macro=true";
import { default as indexF25A07E93PMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/confirm-booking/index.vue?macro=true";
import { default as indexsI9aLfH4jdMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/index.vue?macro=true";
import { default as indexWSuVgpJLkcMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/payment/index.vue?macro=true";
import { default as indexMeHK8O0GvGMeta } from "/__w/landing-page/landing-page/pages/experiences/[id]/success/index.vue?macro=true";
import { default as indexS953jUJuR6Meta } from "/__w/landing-page/landing-page/pages/experiences/index.vue?macro=true";
import { default as indexy1Aky82SwkMeta } from "/__w/landing-page/landing-page/pages/faqs/index.vue?macro=true";
import { default as index6WHDQYTyszMeta } from "/__w/landing-page/landing-page/pages/guides/[id]/index.vue?macro=true";
import { default as indexBG1OrYfAh6Meta } from "/__w/landing-page/landing-page/pages/index.vue?macro=true";
import { default as indexGCmocRIYBGMeta } from "/__w/landing-page/landing-page/pages/login/index.vue?macro=true";
import { default as indexyqmPn2aJLZMeta } from "/__w/landing-page/landing-page/pages/nft-wallet/[id]/index.vue?macro=true";
import { default as indexaPcDpJEtkEMeta } from "/__w/landing-page/landing-page/pages/nft-wallet/index.vue?macro=true";
import { default as indexH8bv50T0F0Meta } from "/__w/landing-page/landing-page/pages/privacy/index.vue?macro=true";
import { default as indexbouK4vAWecMeta } from "/__w/landing-page/landing-page/pages/reservations/[id]/index.vue?macro=true";
import { default as indexHdA7AEhMwIMeta } from "/__w/landing-page/landing-page/pages/reservations/index.vue?macro=true";
import { default as indexNvc2zxVxMhMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/booked/index.vue?macro=true";
import { default as index4hsbEjdSw5Meta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/confirm-booking/index.vue?macro=true";
import { default as indexibC4Sej6SrMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/index.vue?macro=true";
import { default as indexGjN0hJ4VesMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/payment/index.vue?macro=true";
import { default as index9Kin83lMQXMeta } from "/__w/landing-page/landing-page/pages/restaurants/[id]/success/index.vue?macro=true";
import { default as indexMVOkvPSCanMeta } from "/__w/landing-page/landing-page/pages/restaurants/index.vue?macro=true";
import { default as index5y77e46riYMeta } from "/__w/landing-page/landing-page/pages/search/index.vue?macro=true";
import { default as indexvaVHIaF6AeMeta } from "/__w/landing-page/landing-page/pages/suggestions/index.vue?macro=true";
import { default as indexFyFQhQCIMQMeta } from "/__w/landing-page/landing-page/pages/surveys/guide/index.vue?macro=true";
import { default as indexIztRmgCvLvMeta } from "/__w/landing-page/landing-page/pages/surveys/traveller/index.vue?macro=true";
import { default as indexEWLHdA3KyCMeta } from "/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexMbUrXPxpCUMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/booked/index.vue?macro=true";
import { default as indextkTT7K6XlfMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/confirm-booking/index.vue?macro=true";
import { default as indexu2q6NgycJxMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/index.vue?macro=true";
import { default as index7a2ceSnCAJMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/payment/index.vue?macro=true";
import { default as index0AkeSiK03CMeta } from "/__w/landing-page/landing-page/pages/tours/[id]/success/index.vue?macro=true";
import { default as indexopbspky32tMeta } from "/__w/landing-page/landing-page/pages/tours/index.vue?macro=true";
import { default as indexqtm2giEJgeMeta } from "/__w/landing-page/landing-page/pages/your-itineraries/[id]/index.vue?macro=true";
import { default as indexikuCWdka1aMeta } from "/__w/landing-page/landing-page/pages/your-itineraries/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/__w/landing-page/landing-page/pages/about-us/index.vue")
  },
  {
    name: "accommodations-id-booked",
    path: "/accommodations/:id()/booked",
    meta: indexEM211K2zS6Meta || {},
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/booked/index.vue")
  },
  {
    name: "accommodations-id-confirm-booking",
    path: "/accommodations/:id()/confirm-booking",
    meta: indexSvvPPaDEsYMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/index.vue")
  },
  {
    name: "accommodations-id-confirm-booking-room-types",
    path: "/accommodations/:id()/confirm-booking/room-types",
    meta: room_45typesL6iEgJeU6EMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/confirm-booking/room-types.vue")
  },
  {
    name: "accommodations-id",
    path: "/accommodations/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/index.vue")
  },
  {
    name: "accommodations-id-payment",
    path: "/accommodations/:id()/payment",
    meta: indexkBQnKs7X59Meta || {},
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/payment/index.vue")
  },
  {
    name: "accommodations-id-success",
    path: "/accommodations/:id()/success",
    meta: indexOh8KX4Gi5EMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/[id]/success/index.vue")
  },
  {
    name: "accommodations",
    path: "/accommodations",
    component: () => import("/__w/landing-page/landing-page/pages/accommodations/index.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexQWTfd6C61KMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/account/index.vue")
  },
  {
    name: "activities-id-booked",
    path: "/activities/:id()/booked",
    meta: indexOaCdqv0UnnMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/booked/index.vue")
  },
  {
    name: "activities-id-confirm-booking",
    path: "/activities/:id()/confirm-booking",
    meta: indexn4yh0WphGFMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/confirm-booking/index.vue")
  },
  {
    name: "activities-id",
    path: "/activities/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/index.vue")
  },
  {
    name: "activities-id-payment",
    path: "/activities/:id()/payment",
    meta: indexa4TrHMsfftMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/payment/index.vue")
  },
  {
    name: "activities-id-success",
    path: "/activities/:id()/success",
    meta: indexpAvpVVXWYrMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/activities/[id]/success/index.vue")
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/__w/landing-page/landing-page/pages/activities/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/__w/landing-page/landing-page/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/__w/landing-page/landing-page/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    component: () => import("/__w/landing-page/landing-page/pages/auth/reset-password.vue")
  },
  {
    name: "auth-validate-email",
    path: "/auth/validate-email",
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-email.vue")
  },
  {
    name: "auth-validate-phone",
    path: "/auth/validate-phone",
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-phone.vue")
  },
  {
    name: "bye",
    path: "/bye",
    component: () => import("/__w/landing-page/landing-page/pages/bye/index.vue")
  },
  {
    name: "community",
    path: "/community",
    component: () => import("/__w/landing-page/landing-page/pages/community/index.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/__w/landing-page/landing-page/pages/contact-us/index.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/__w/landing-page/landing-page/pages/cookies/index.vue")
  },
  {
    name: "destinations-id",
    path: "/destinations/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/destinations/[id]/index.vue")
  },
  {
    name: "destinations",
    path: "/destinations",
    component: () => import("/__w/landing-page/landing-page/pages/destinations/index.vue")
  },
  {
    name: "donatella",
    path: "/donatella",
    meta: indexChFVAVuhdIMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/donatella/index.vue")
  },
  {
    name: "experiences-id-booked",
    path: "/experiences/:id()/booked",
    meta: indexvUGuDSmPdXMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/booked/index.vue")
  },
  {
    name: "experiences-id-confirm-booking",
    path: "/experiences/:id()/confirm-booking",
    meta: indexF25A07E93PMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/confirm-booking/index.vue")
  },
  {
    name: "experiences-id",
    path: "/experiences/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/index.vue")
  },
  {
    name: "experiences-id-payment",
    path: "/experiences/:id()/payment",
    meta: indexWSuVgpJLkcMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/payment/index.vue")
  },
  {
    name: "experiences-id-success",
    path: "/experiences/:id()/success",
    meta: indexMeHK8O0GvGMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/experiences/[id]/success/index.vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    component: () => import("/__w/landing-page/landing-page/pages/experiences/index.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/__w/landing-page/landing-page/pages/faqs/index.vue")
  },
  {
    name: "guides-id",
    path: "/guides/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/guides/[id]/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/__w/landing-page/landing-page/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/__w/landing-page/landing-page/pages/login/index.vue")
  },
  {
    name: "nft-wallet-id",
    path: "/nft-wallet/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/nft-wallet/[id]/index.vue")
  },
  {
    name: "nft-wallet",
    path: "/nft-wallet",
    component: () => import("/__w/landing-page/landing-page/pages/nft-wallet/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/__w/landing-page/landing-page/pages/privacy/index.vue")
  },
  {
    name: "reservations-id",
    path: "/reservations/:id()",
    meta: indexbouK4vAWecMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/reservations/[id]/index.vue")
  },
  {
    name: "reservations",
    path: "/reservations",
    meta: indexHdA7AEhMwIMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/reservations/index.vue")
  },
  {
    name: "restaurants-id-booked",
    path: "/restaurants/:id()/booked",
    meta: indexNvc2zxVxMhMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/booked/index.vue")
  },
  {
    name: "restaurants-id-confirm-booking",
    path: "/restaurants/:id()/confirm-booking",
    meta: index4hsbEjdSw5Meta || {},
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/confirm-booking/index.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/index.vue")
  },
  {
    name: "restaurants-id-payment",
    path: "/restaurants/:id()/payment",
    meta: indexGjN0hJ4VesMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/payment/index.vue")
  },
  {
    name: "restaurants-id-success",
    path: "/restaurants/:id()/success",
    meta: index9Kin83lMQXMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/[id]/success/index.vue")
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/__w/landing-page/landing-page/pages/restaurants/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: index5y77e46riYMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/search/index.vue")
  },
  {
    name: "suggestions",
    path: "/suggestions",
    component: () => import("/__w/landing-page/landing-page/pages/suggestions/index.vue")
  },
  {
    name: "surveys-guide",
    path: "/surveys/guide",
    component: () => import("/__w/landing-page/landing-page/pages/surveys/guide/index.vue")
  },
  {
    name: "surveys-traveller",
    path: "/surveys/traveller",
    component: () => import("/__w/landing-page/landing-page/pages/surveys/traveller/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue")
  },
  {
    name: "tours-id-booked",
    path: "/tours/:id()/booked",
    meta: indexMbUrXPxpCUMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/booked/index.vue")
  },
  {
    name: "tours-id-confirm-booking",
    path: "/tours/:id()/confirm-booking",
    meta: indextkTT7K6XlfMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/confirm-booking/index.vue")
  },
  {
    name: "tours-id",
    path: "/tours/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/index.vue")
  },
  {
    name: "tours-id-payment",
    path: "/tours/:id()/payment",
    meta: index7a2ceSnCAJMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/payment/index.vue")
  },
  {
    name: "tours-id-success",
    path: "/tours/:id()/success",
    meta: index0AkeSiK03CMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/tours/[id]/success/index.vue")
  },
  {
    name: "tours",
    path: "/tours",
    component: () => import("/__w/landing-page/landing-page/pages/tours/index.vue")
  },
  {
    name: "your-itineraries-id",
    path: "/your-itineraries/:id()",
    component: () => import("/__w/landing-page/landing-page/pages/your-itineraries/[id]/index.vue")
  },
  {
    name: "your-itineraries",
    path: "/your-itineraries",
    meta: indexikuCWdka1aMeta || {},
    component: () => import("/__w/landing-page/landing-page/pages/your-itineraries/index.vue")
  }
]